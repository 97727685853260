import React, { FunctionComponent } from "react";

export const OutlineEgg: FunctionComponent<React.SVGAttributes<SVGElement>> = (
    props
) => (
    <svg
        width="300"
        height="220"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="m197.429 1.587.045-1.5-.045 1.5c23.947.71 44.462 6.318 61.601 16.767 17.042 10.39 28.818 26.361 35.296 48.043 6.477 21.682 5.402 41.532-3.129 59.637-8.579 18.206-22.636 34.217-42.248 48.017-19.634 13.816-42.878 24.749-69.754 32.784-27.002 8.072-52.526 11.718-76.581 10.964-24.029-.754-44.56-6.424-61.653-16.952-17.002-10.471-28.758-26.486-35.236-48.169l-1.434.428 1.434-.428C-.792 130.866.245 110.995 8.73 92.975c8.533-18.12 22.614-34.036 42.325-47.73 19.736-13.71 43.096-24.611 70.1-32.684C148.033 4.526 173.455.876 197.429 1.587Z"
            stroke="currentColor"
            strokeWidth="3"
        />
    </svg>
);
